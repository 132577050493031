export default {
    namespaced: true,
    state: {
        userOpenId: {},
        // token: ''
    },
    mutations: {
        // 获取orderId
        setuserOpenId(state, payload) {
            state.userOpenId = payload
        },
        // setToken(state, payload) {
        //     state.userOpenId = payload
        // }
    },
    actions: {
        getuserOpenId(context, payload) {
            context.commit('setuserOpenId', payload)
        },
        // 获取token
        // getToken(context, payload) {
        //     context.commit('setToken', payload)
        // }
    }

}