export default {
  namespaced: true,
  state: {
    usersettle: [], //   结清用户信息
  },
  mutations: {
    // 获取orderId
    setusersettle(state, payload) {
      state.usersettle = payload;
    },
    //   结清用户信息
  },
  actions: {
    getusersettle(context, payload) {
      context.commit("setusersettle", payload);
    },
  },
};
