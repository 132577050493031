import "babel-polyfill";
import Vue from 'vue'
import App from './App.vue'
// 引入vant
import Vant from 'vant'
import '@/assets/fonts/iconfont.css'
import 'vant/lib/index.css'
import router from './router'
import store from './store'


// 加载动态设置 REM 基准值
import 'amfe-flexible'
import '@/permission'// permission control
// import '@/styles/index.less'

Vue.use(Vant)

//钩子函数
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
