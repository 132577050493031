<template>
  <div id="app">
    <!-- 一级路由占位 -->

    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {},
  // mounted() {
  //   var is_mobile =
  //     navigator.userAgent
  //       .toLowerCase()
  //       .match(
  //         /(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
  //       ) != null;
  //   if (is_mobile) {
  //     // window.location.href = "手机端";
  //     console.log("手机端");
  //   } else {
  //     console.log("PC端");
  //     // location.replace("http://www.onlymid.com.cn/");
  //   }
  // },
};
</script>
<style lang="less"></style>
