
export default {
    namespaced: true,
    state: {
        repayment: ''
    },
    mutations: {
        // 获取orderId
        setrepayment(state, payload) {
            state.repayment = payload
        }
    },
    actions: {
        getrepayment(context, payload) {
            context.commit('setrepayment', payload)
        }
    }

}
