import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import refund from "./modules/refund";
import mandate from "./modules/mandate";
import settle from "./modules/settle";
import settings from "./modules/settings";
import createVuexPersisted from "vuex-persistedstate";
import token from "./modules/token";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    refund,
    mandate,
    settle,
    settings,
    token,
  },

  plugins: [
    createVuexPersisted({
      reducer(state) {
        return {
          user: {
            userInfo: state.user.userInfo,
            bindin: state.user.userInfo,
            mandateUser: state.user,
          },
          settle: {
            usersettle: state.settle.usersettle
          },
          token: {
            token: state.token.token,
          },
        };
      },
    }),
  ],
});

export default store;
