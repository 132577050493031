// import store from '@/store'
/**
 * 用户相关的请求模块
 */
import request from '@/utils/request'
/**
 * 用户登录
 */
export const login = (data) => {
    return request({
        method: 'post',
        url: '/customer/login',
        data
        // headers: {
        //     // contentType: 'application/x-www-form-urlencoded',
        //     Authorization: `Bearer ${store.state.user.userInfo.token}`
        // }
        // headers: {
        //     ContentType: 'application/x-www-form-urlencoded'
        // }
    })
}
/* 发送验证码  注意 没手机没分钟1次 */
export const sendSms = ({ phone, name }) => {
    return request({
        method: 'post',
        url: '/sendSms',
        data: {
            phone,
            name
        },
        headers: {
            ContentType: 'application/x-www-form-urlencoded'
        }

    })
}
export const getBindingApi = (data) => {
    return request({
        method: 'post',
        url: '/customer/bind',
        data
    })
}
