// 引入axios
import service from "axios";
import { Toast } from "vant";
import { getTokenTime } from "@/utils/auth";
import store from "@/store";

import router from "@/router";
// function isTimeOut() {
//     const currentTime = Date.now()
//     const tokenTime = getTokenTime()
//     const timeout = 2 * 60 * 60 * 1000
//     return currentTime - tokenTime > timeout
// }
// 配置axios的默认配置
// 创建一个全新的axios的对象
const request = service.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 3000
});
request.interceptors.request.use(
  async function (config) {
    if (store.state.user.userInfo) {
      // console.log(store.state.user.bindin.token, "全局token");
      if (store.state.user.userInfo.token || store.state.user.bindin.token) {
        // console.log("全局token", store.state.user.userInfo.token);
        config.headers.Authorization = `${store.state.user.bindin.token}` || "";
      }
    }
    // 注意：这里必须返回config配置对象 否则请求就停在这里出不去
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  async function (res) {
    console.log(res);
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (res.data.code == 200) {
      // Toast.success(res.data.message)
    } else {
      if (res?.data?.code === 401) {
        Toast.fail(res.data.message);
      } else if (res?.data?.code === 500) {
        Toast.fail(res.data.message);
      } else {
        Toast.fail(res.data.message);
      }
    }
    return res;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default request;
