export default {
    namespaced: true,
    state: {
      
        token: ''
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload
        }
    },
    actions: {
        // 获取token
        async getToken(context, payload) {
            // const res = await login(payload)
            context.commit('setToken', payload)
            // console.log(res.data.data.token);
        }
    },
}