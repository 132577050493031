import { login, getBindingApi } from "@/api/user";
import { Toast } from "vant";
import router from "@/router";
export default {
  namespaced: true,
  state: {
    userInfo: {} && "",
    // bindin: {},
    // mandateUser: {}
    token: "",
  },
  mutations: {
    setuserInfo(state, payload) {
      state.userInfo = payload;
    },
    setBindin(state, payload) {
      state.userInfo = payload;
    },
    setmandateUser(state, payload) {
      state.userInfo = payload;
    },
  },
  actions: {
    async getuserInfo(context, payload) {
      const res = await login(payload);
      // 如果后端返回的字段500登陆失败
      if (res.data.code === 500) {
        Toast.fail(res.data.message);
      } else {
        // 登录成功，跳转回原来页面
        Toast.success("登录成功");
        // router.push("/home");
      }
      console.log("登录成功", res);
      context.commit("setuserInfo", res.data.data);
      context.commit("token", res.data.data.token);
    },
    async getBindin(context, payload) {
      try {
        const res = await getBindingApi(payload);
        // 如果后端返回的字段500登陆失败
        if (res.data.code === 500) {
          Toast.fail(res.data.message);
        } else {
          // 登录成功，跳转回原来页面
           Toast.success("绑定成功，请重新登录");
        //   router.push("/home");
        }
        // context.commit("setBindin", res.data.data);
      } catch (err) {
        console.log(err);
      }
    },
    // 获取
    async getmandateUser(context, payload) {
      context.commit("setmandateUser", payload);
      // console.log(res.data.data.token);
    },

    // 退出
    logout(context) {
      context.commit("setuserInfo", {});
    },
  },
};
