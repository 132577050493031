import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/mandate" },
  {
    path: "/mandate",
    name: "mandate",
    component: () => import("@/views/mandate"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home"),
    meta: { authRequired: true },
    meta: {
      title: "首页", //这是重点
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
    meta: {
      title: "登录", //这是重点
    },
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/message"),
    meta: {
      title: "信息认证", //这是重点
    },
  },
  {
    path: "/refund",
    name: "refund",

    component: () => import("@/views/refund"),
    meta: {
      title: "还款信息", //这是重点
    },
  },

  {
    path: "/Payment",
    name: "Payment",
    component: () => import("@/views/refund/Payment"),
    meta: {
      title: "还款详情", //这是重点
    },
  },
  {
    path: "/flowPath",
    name: "flowPath",
    component: () => import("@/views/settle/flowPath"),
    meta: {
      title: "结清申请", //这是重点
    },
  },
  {
    path: "/settle",
    name: "settle",
    component: () => import("@/views/settle"),
    meta: {
      title: "结清申请", //这是重点
    },
  },
  {
    path: "/settleInput",
    name: "settleInput",
    component: () => import("@/views/settle/settleInput"),
    meta: {
      title: "结清申请", //这是重点
    },
  },
  {
    path: "/statusbar",
    name: "statusbar",
    component: () => import("@/views/settle/statusbar "),
    meta: {
      title: "结清申请", //这是重点
    },
  },
  {
    path: "/complaint",
    name: "complaint",
    component: () => import("@/views/complaint"),
    meta: {
      title: "咨询投诉", //这是重点
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/logout"),
    meta: {
      title: "退出登录", //这是重点
    },
  },
  {
    path: "/ceshi",
    name: "ceshi",
    component: () => import("@/views/ceshi"),
    meta: {
      title: "测试", //这是重点
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  // base: '/Project/',
  routes,
});

export default router;
